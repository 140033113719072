@import url(https://fonts.googleapis.com/css?family=Barlow&display=swap);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ql-toolbar {
  padding: 11px !important;
  background-color: rgba(0, 0, 0, 0.04);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 0 !important;
}
.ql-toolbar.ql-snow .ql-picker-label.ql-active {
  color: black !important;
  font-weight: bold;
}

p.MuiFormHelperText-root span {
  display: none;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: -webkit-flex;
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  main > div:nth-child(2) {
    padding: 24px;
  }
}

main > div.MuiDrawer-root > div.MuiPaper-root {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiMenuItem-root {
  overflow: visible !important;
}

